<template>
  <page back :logo="false">
    <section class="m-home">
      <h2 v-if="cabinet" class="pricing">
        <span class="theme-t"
          >{{ cabinet.jifeiDanwei }}-{{
            i18n.Minutes && i18n.Minutes.toLocaleUpperCase()
          }}</span
        >
        FOR
        <span class="theme-t">{{ currencyName }}{{ cabinet.jifei }}</span>
      </h2>
      <div class="pricing-details" @click="goRouter('/expense')">
        {{ i18n.pricing_details }}
      </div>
      <div class="paymethod">
        <div class="paymethod-title">{{ i18n.selectmethod }}</div>
        <div v-if="payModeList.length > 0">
          <template v-for="item in payModeList">
            <div
              class="paymethod-item"
              :class="{
                select: payMode && payMode.pId === item.pId
              }"
              v-if="
                item.phone === 'all' ||
                item.phone === system ||
                item.phone === browser ||
                (item.phone === 'phone' && system !== '')
              "
              :key="item.pId"
              @click="payMode = item"
            >
              <img
                alt=""
                :src="paymentTypeIcon(item.pPaymentType).icon"
                :class="paymentTypeIcon(item.pPaymentType).custom"
              />
              <span>{{ item.label }}</span>
            </div>
          </template>
          <div class="paymethod-item">
            <img
              alt=""
              class="icon_more"
              src="../../assets/imgs/paymethod_more.png"
            />
          </div>
        </div>

        <div class="coupon-code">
          <div class="coupon-input">
            <p class="preicon">PROMO CODE</p>
            <input
              type="text"
              class="text"
              v-model="couponCode"
              placeholder="Enter promo code"
            />
          </div>
        </div>
        <div class="rentbtn" @click="onRent">{{ i18n.rent }}</div>
        <div class="home-agreen">
          {{ i18n.tips }}
          <a
            @click="
              viewAgreen('https://m.needzappy.com/content/privacy_policy.html')
            "
            >{{ i18n.privacy }}</a
          >
          {{ i18n.and }}
          <a
            @click="
              viewAgreen('https://m.needzappy.com/content/user_agreement.html')
            "
            >{{ i18n.term }}</a
          >
        </div>
      </div>

      <div class="home-logo">
        <img src="../../assets/imgs/icon_logo.png" alt="" />
      </div>

      <div v-if="loginOptionShow" class="mask" @click="loginOptionShow = false">
        <div class="mask-main" @click.stop>
          <div class="appleline"></div>
          <div class="phonelogin">
            <div class="area" @click="isAreaView = true">
              <span>+{{ areaCode }}</span>
              <div class="area-down"></div>
            </div>
            <input
              type="number"
              v-model="phone"
              :placeholder="i18n.phonenumber"
            />
            <img
              src="../../assets/imgs/icon_next_circle.png"
              alt=""
              @click="onSendCode"
            />
          </div>
          <LoginGoogle
            ref="loginGoogle"
            @success="loadUser(true)"
            class="mb-8 minheight-5"
          >
            <div class="buttonDiv" id="buttonDiv"></div>
            <div class="googlewarp">
              <img src="../../assets/imgs/icon_google.png" alt="" />
              <span>{{ i18n.logingg }}</span>
            </div>
          </LoginGoogle>
          <div class="mask-agreen">
            {{ i18n.tips }}
            <a
              @click="
                viewAgreen(
                  'https://m.needzappy.com/content/privacy_policy.html'
                )
              "
              >{{ i18n.privacy }}</a
            >
            {{ i18n.and }}
            <a
              @click="
                viewAgreen(
                  'https://m.needzappy.com/content/user_agreement.html'
                )
              "
              >{{ i18n.term }}</a
            >
          </div>
        </div>
      </div>
      <popup-warp v-model="isCreditcard">
        <div>
          <div class="creditcard-input">
            <div id="credit-card-element"></div>
          </div>
          <div class="btn creditcard-btn" @click="onStripeSetup">
            {{ i18nConfirm.title }}
          </div>
        </div>
      </popup-warp>
      <select-area v-model="isAreaView" @select="onSelct"></select-area>
      <LoginCode
        v-if="loginCodeShow"
        @close="(res) => (loginCodeShow = res)"
        @submit="onLogin"
      ></LoginCode>
    </section>
  </page>
</template>
<script>
import md5 from "js-md5"
import { mapState, mapGetters } from "vuex"
import { getToken } from "../../utils/stripe_pay.js"
import stripeMixin from "../../mixins/stripe"
import { generateUUID } from "../../utils/index"
import { baseURL } from "../../utils/http"
import { SelectArea } from "../../components/select"
import { LoginGoogle } from "../../components/login/index"
import LoginCode from "./component/logincode.vue"
import { PopupWarp } from "../../components/popup"
import { USER_INFO, LOGIN_CODE, SEND_CODE, USER_CAPTCHA } from "../../apis/user"
import { QR_CODE, ZH_CODE } from "../../apis/cabinet"
import {
  PAYMODE,
  STRIPE_PREAUTH_OF_CONFIRM,
  STRIPE_PREPAY_SPTOKEN,
  COUPON_SEND,
  STRIPE_PREAUTH_AUTO_CONFIRM
} from "../../apis/payment"
export default {
  mixins: [stripeMixin],
  components: {
    // PreAuthorTip,
    // RentCostTip,
    LoginCode,
    LoginGoogle,
    PopupWarp,
    SelectArea
  },
  watch: {
    "payMode.pPaymentType": {
      handler(val, oldVal) {
        console.log("payMode", val)
        if (
          val === "APPLE_PAY" ||
          val === "STRIPE_APPLE" ||
          val === "STRIPE_GOOGLE" ||
          val === "GOOGLE_PAY"
        ) {
          if (!this.stripePayRequest && this.cabinet) {
            this.$loading(true)
            this.getStripeToken()
          }
        }
      },
      immediate: true, // 立即执行
      deep: true // 深度监听复杂类型内变化
    },
    cabinet(val) {
      if (val) {
        console.log("设备信息", val)
        this.getPayMode()
      }
    }
  },
  computed: {
    ...mapState([
      "browser",
      "user",
      "token",
      "cabinet",
      "system",
      "qrcode",
      "agentId",
      "loginMode"
    ]),
    ...mapGetters(["locale"]),
    scanUrl() {
      if (this.qrcode || this.$route.query.qrcode) {
        return `${QR_CODE}?id=${this.qrcode || this.$route.query.qrcode}`
      }
      if (this.zhcode || this.$route.query.zhcode) {
        return `${ZH_CODE}/${this.zhcode}`
      }
      return ""
    },
    i18n() {
      return this.$t("loginborrow")
    },
    i18nMsg() {
      return this.$t("msg")
    },
    i18nConfirm() {
      return this.$t("confirm")
    },
    i18nMine() {
      return this.$t("mine")
    },
    currency() {
      return (
        (this.cabinet && this.cabinet.currency) ||
        (this.user && this.user.pcurrency) ||
        "USD"
      )
    },
    currencyName() {
      return (
        (this.cabinet && this.cabinet.currencyName) ||
        (this.user && this.user.pcurrencyName) ||
        "$"
      )
    },
    placeholder() {
      return this.cabinet && this.cabinet.yajin
        ? `Pre-authorisation ${this.currency}${
            this.cabinet && this.cabinet.yajin
          }`
        : "Pre-authorisation "
    }
  },
  data() {
    return {
      isAreaView: false,
      areaCode: "1",
      captchaUrl: "",
      code: "",
      uuid: "",
      phone: "",
      cardNum: "",
      couponCode: "",
      preAuthorShow: false,
      loginOptionShow: false,
      rentCostShow: false,
      loginCodeShow: false,
      isCreditcard: false,
      currentLoginMode: "phone",
      payModeList: [],
      payMode: {
        pPaymentType: "STRIPE_CARD"
      },
      cardStyle: {
        base: {
          color: "#32325d",
          fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
          fontSmoothing: "antialiased",
          fontSize: "16px",
          "::placeholder": {
            color: "#aab7c4"
          }
        },
        invalid: {
          color: "#fa755a",
          iconColor: "#fa755a"
        }
      },
      stripePayRequest: null,
      creditcard: null,
      clientSecret: null,
      creditOrder: null
    }
  },
  methods: {
    goRouter(path) {
      this.$loading(true)
      this.$router.push(path)
      this.$loading(false)
    },
    showLangSelect() {
      this.$store.commit("isLangView", true)
    },
    goOrder() {
      this.$router.push("/order")
    },
    onSelct(val) {
      this.areaCode = val
    },
    showRentCostPop() {
      if (this.cabinet && this.cabinet.yajin) {
        this.rentCostShow = true
      } else {
        this.$toast(this.i18nMine.login_tips)
      }
    },
    viewAgreen(url) {
      window.open(url)
    },
    onCaptcha() {
      this.code = ""
      this.uuid = generateUUID()
      this.captchaUrl = `${baseURL}${USER_CAPTCHA}?uuid=${this.uuid}`
    },
    onLogout() {
      console.log("退出==")
      this.$confirm({
        title: this.i18nMsg.title,
        content: this.i18nMsg.msg_logout,
        confirmText: this.i18nMsg.confirm,
        cancelText: this.i18nMsg.cancel
      }).then(() => {
        this.$store.commit("user", "")
        this.$store.commit("token", "")
        this.$store.commit("appleIdToken", "")
        // this.$store.commit('qrcode', '')
        this.payModeList = []
      })
    },
    onRent() {
      console.log(this.cabinet)
      if (this.user) {
        if (
          this.cabinet &&
          this.cabinet.zujieNum &&
          this.cabinet.zujieNum > 0
        ) {
          this.$toast(this.i18nMsg.exceedorder)
          return
        }
        this.getCouponCode()
      } else {
        this.loginOptionShow = true
      }
    },
    onPay() {
      if (
        this.payMode.pPaymentType === "APPLE_PAY" ||
        this.payMode.pPaymentType === "STRIPE_APPLE" ||
        this.payMode.pPaymentType === "STRIPE_GOOGLE" ||
        this.payMode.pPaymentType === "GOOGLE_PAY"
      ) {
        this.stripePayRequest.show()
      } else if (this.payMode.pPaymentType === "STRIPE_CARD") {
        this.onCreditcard()
      }
    },
    onScan() {
      let url = this.scanUrl
      this.inputCodeShow = false
      this.$loading(true)
      this.$post(
        url,
        {},
        (resp) => {
          this.$loading(false)
          this.$store.commit("cabinet", {
            qrcode: this.code,
            ...resp
          })
          if (resp.zujieNum && resp.zujieNum > 0) {
            this.$confirm({
              title: this.i18n.msg_fail_title,
              content: `${this.i18n.msg_rentcount}`.format(resp),
              confirmText: this.i18nMsg.confirm,
              cancelText: this.i18nMsg.cancel
            })
              .then(() => {
                // this.$store.commit('qrcode', '')
              })
              .catch(() => {
                // this.$store.commit('qrcode', '')
              })
          }
        },
        (error) => {
          this.$loading(false)
          let errstr = ""
          if (error.includes("is offline") || error.includes("离线")) {
            errstr = this.$t("home.offline", { devicecode: this.qrcode })
          } else if (error.includes("Permission") || error.includes("权限")) {
            errstr = this.$t("home.permissionfail")
          } else if (error.includes("not bound") || error.includes("未绑定")) {
            errstr = this.$t("home.unbound", { devicecode: this.qrcode })
          } else if (error.includes("No battery") || error.includes("可用")) {
            errstr = this.$t("home.nobattery", { devicecode: this.qrcode })
          } else {
            errstr = error
          }
          this.$confirm({
            title: this.i18n.msg_fail_title,
            confirmText: this.i18nMsg.confirm,
            content: errstr,
            showCancel: false
          }).then(() => {
            this.$store.commit("qrcode", "")
          })
        }
      )
    },
    loadUser(isOnScan = false) {
      this.$get(
        USER_INFO,
        {},
        (resp) => {
          if (resp.user) {
            this.$store.commit("user", resp.user)
            this.loginOptionShow = false
            if (isOnScan && this.qrcode) {
              this.onScan()
            }
          } else {
            this.$confirm({
              title: this.i18n.msg_fail_title,
              content: this.i18n.msg_fail_user,
              showOk: false,
              cancelText: this.i18nMsg.cancel
            }).then(() => {
              this.loadUser()
            })
          }
        },
        (error) => {
          this.$confirm({
            title: this.i18n.msg_fail_title,
            content: this.i18nMsg.userinfo_fail,
            confirmText: this.i18nMsg.confirm,
            showCancel: false
          })
          console.log(error)
        }
      )
    },
    getStripeToken() {
      getToken(this.cabinet && this.cabinet.yajin, this.onStripe)
        .then((data) => {
          console.log("stripe getToken", data)
          this.stripePayRequest = data
          this.$loading(false)
        })
        .catch((error) => {
          this.stripePayRequest = null
          console.log("stripe getTokenerr", error)
          this.$loading(false)
          this.$confirm({
            title: this.i18n.msg_fail_title,
            content: this.i18n.msg_fail_support,
            showCancel: false,
            confirmText: this.i18n.msg_fail_confirm
          })
        })
    },
    // apple和Google支付时调用
    onStripe(token, ev) {
      let url = ""
      let datas = {}
      if (this.cabinet) {
        url = STRIPE_PREPAY_SPTOKEN
        datas = {
          qrcode: this.cabinet.qrcode,
          stripeToken: token, // stripe官方支付的一次性token
          paymentId: this.payMode.pId // 代理或平台支付配置id
        }
      } else {
        this.$confirm({
          title: this.i18nMsg.title,
          content: this.i18nMsg.error,
          confirmText: this.i18nMsg.confirm,
          showCancel: false
        }).then(() => {
          this.$router.push("/")
        })
      }
      this.$post(
        url,
        datas,
        (resp) => {
          if (ev && ev.complete) {
            ev.complete("success")
          }
          this.$loading(false)
          console.log(resp)
          if (this.payType === "CZ") {
            this.$confirm({
              title: this.i18nMsg.title,
              content: this.i18nMsg.success_payment,
              confirmText: this.i18nMsg.confirm,
              showCancel: false
            }).then(() => {
              this.$router.replace("/mine")
            })
          } else {
            this.$toast(this.i18nMsg.success_payment)
            if (this.payType === "FK") {
              this.$router.replace(
                `/result?tradeNo=${resp.data.orderId}&payMode=stripe&payType=FK`
              )
              return
            }
            this.$router.replace(
              `/result?tradeNo=${resp.data.orderId}&payMode=stripe`
            )
          }
        },
        (error) => {
          if (ev && ev.complete) {
            ev.complete("fail")
          }
          this.$loading(false)
          console.log(error)
          this.$confirm({
            title: this.i18nMsg.title,
            content: error,
            confirmText: this.i18nMsg.confirm,
            showCancel: false
          })
        }
      )
    },
    onCreditcard() {
      this.$loading(true)
      this.$post(
        `${STRIPE_PREAUTH_OF_CONFIRM}/${this.qrcode}`,
        {
          paymentId: this.payMode.pId,
          amount: this.cabinet.yajin
        },
        (resp) => {
          this.$loading(false)
          console.log(resp)
          this.clientSecret = resp.data
          this.onLoadElement()
        },
        (error) => {
          this.$loading(false)
          this.$toast(error)
        }
      )
    },
    getCouponCode() {
      if (!this.cabinet) {
        return
      }
      this.$loading(true)
      this.$get(
        `${COUPON_SEND}?code=${this.couponCode}`,
        {},
        (resp) => {
          this.$loading(false)
          console.log(resp)
          this.onPay()
        },
        (error) => {
          this.$loading(false)
          console.log(error)
          this.onPay()
        }
      )
    },
    getPayMode() {
      this.$loading(true)
      const qrcode = (this.cabinet && this.cabinet.cabinetID) || ""
      this.$get(
        `${PAYMODE}?id=${qrcode}`,
        {},
        (resp) => {
          this.$loading(false)
          if (resp.data) {
            let list = resp.data
            list.map((v, i, arr) => {
              if (v.pPaymentType === "STRIPE_CARD") {
                v.label = "Credit Card"
                v.phone = "all"
              } else if (v.pPaymentType === "STRIPE_WECHAT") {
                v.label = "WeChat"
                v.phone = "wechat"
              } else if (
                v.pPaymentType === "APPLE_PAY" ||
                v.pPaymentType === "STRIPE_APPLE"
              ) {
                // v.label = "Apple Pay"
                v.phone = "ios"
              } else if (
                v.pPaymentType === "GOOGLE_PAY" ||
                v.pPaymentType === "STRIPE_GOOGLE"
              ) {
                // v.label = "Google Pay"
                v.phone = "android"
              }
            })
            this.payModeList = list
            if (list.length > 0) {
              this.payMode = list[0]
              this.loadStripeScript(list[0].publicKey)
            }
            console.log("payMode~list:", list)
          }
        },
        (error) => {
          this.$loading(false)
          this.$confirm({
            title: this.i18n.msg_fail_title,
            content: this.i18n.msg_fail_paymod,
            confirmText: this.i18n.msg_fail_retry,
            cancelText: this.i18n.msg_fail_cancel
          }).then(() => {
            this.getPayMode()
          })
          console.log(error)
        }
      )
    },
    // 支付类型图标
    paymentTypeIcon(pPaymentType) {
      let payType = {
        icon: "",
        custom: ""
      }
      if (pPaymentType === "GOOGLE_PAY" || pPaymentType === "STRIPE_GOOGLE") {
        payType = {
          custom: "icon",
          icon: require("../../assets/imgs/icon_googlepay.png")
        }
      } else if (
        pPaymentType === "APPLE_PAY" ||
        pPaymentType === "STRIPE_APPLE"
      ) {
        payType = {
          custom: "icon",
          icon: require("../../assets/imgs/icon_applepay.png")
        }
      } else {
        payType = {
          custom: "text",
          icon: require("../../assets/imgs/icon_card_Unselected.png")
        }
      }
      return payType
    },
    onLoadElement() {
      if (window.stripeElements) {
        // this.loadStripeScript(this.payMode.publicKey)
        if (!this.creditcard) {
          this.creditcard = window.stripeElements.create("card", {
            style: this.cardStyle
          })
        }
        this.creditcard.mount("#credit-card-element")
        this.$nextTick(() => {
          this.isCreditcard = true
        })
      } else {
        this.$toast("loading...")
        this.loadStripeScript()
        setTimeout(() => {
          this.onLoadElement()
        }, 500)
      }
    },
    onStripeSetup() {
      const vm = this
      vm.$loading(true)
      // paymentIntent通过付款方式确认卡设置
      window.stripe
        .confirmCardPayment(vm.clientSecret.clientSecret, {
          payment_method: {
            card: vm.creditcard,
            billing_details: {
              name: "test"
            }
          }
        })
        .then((result) => {
          console.log(result)
          vm.$loading(false)
          if (result.error) {
            vm.$toast(result.error.message)
          } else {
            vm.confirmSetup(
              result.paymentIntent.id,
              result.paymentIntent.payment_method
            )
          }
        })
      // 订单付款
      // paymentIntent通过付款方式确认卡付款
      // window.stripe
      //   .confirmCardPayment(vm.clientSecret.clientSecret, {
      //     payment_method: {
      //       card: vm.creditcard,
      //       billing_details: {
      //         name: 'test'
      //       }
      //     }
      //   })
      //   .then(result => {
      //     console.log(result)
      //     vm.$loading(false)
      //     if (result.error) {
      //       vm.$toast(result.error.message)
      //     } else {
      //       vm.confirmSetup(result.paymentIntent.id, result.paymentIntent.payment_method)
      //     }
      //   })
    },
    confirmSetup(id, paymentMethod) {
      this.$loading(true)
      this.$get(
        `${STRIPE_PREAUTH_AUTO_CONFIRM}?tradeNo=${id}&orderType=preAuth&paymentMethod=${paymentMethod}`,
        {
          outTradeNo: this.clientSecret.outTradeNo
        },
        (resp) => {
          this.$loading(false)
          this.isCreditcard = false
          this.$router.replace(
            `/result?tradeNo=${resp.data.orderId}&payMode=stripe`
          )
          console.log(resp)
        },
        (error) => {
          this.$loading(false)
          console.log(error)
        }
      )
    },
    onLogin(code) {
      this.$loading(true)
      this.$post(
        LOGIN_CODE,
        {
          mobile: this.areaCode + this.phone,
          verifyCode: code
        },
        (res) => {
          console.log(res)
          this.$loading(false)
          if (res.token) {
            this.$store.commit("token", res.token)
            this.onCode()
            this.loginCodeShow = false
            this.loginOptionShow = false
            this.$store.commit("loginMode", "phone")
          } else {
            this.$toast(this.i18n.msg_fail_login)
          }
        },
        (err) => {
          console.log(err)
          this.$loading(false)
        }
      )
    },
    onSendCode() {
      if (!this.phone) {
        this.$toast(this.i18nMsg.msg_fail_phone)
        return
      }
      // if (!this.code) {
      //   this.$toast(this.i18nMsg.msg_fail_code)
      //   return
      // }
      let timp = new Date().valueOf()
      let str = timp.toString().replace(/0/g, "8")
      let mdsign = md5(str + "GZzbjkj")
      // console.log(timp, str, mdsign, this.phone)
      this.$loading(true)
      this.$post(
        SEND_CODE,
        {
          mobile: this.areaCode + this.phone,
          timestamp: timp,
          sign: mdsign
          // useDebug: false
        },
        (res) => {
          console.log(res)
          this.$loading(false)
          this.loginCodeShow = true
          // this.onLogin('1234')
        },
        (err) => {
          console.log(err)
          this.$loading(false)
          this.$toast(err)
        }
      )
    },
    onCode() {
      console.log("onCode:", this.qrcode)
      if (this.token) {
        this.loadUser()
      }
      if ((this.qrcode || this.$route.query.qrcode) && this.token) {
        this.onScan()
      }
    }
  },
  created() {
    let qrCode = this.qrcode || this.$route.query.qrcode
    console.log("qrCode====", qrCode)
    if (qrCode) {
      this.$store.commit("qrcode", qrCode)
      if (this.user) {
        this.onCode()
      } else {
        this.onScan()
      }
    }
    // this.onCode()
    // this.onCaptcha()
    // this.getPayMode() // todo
  },
  mounted() {
    if (!this.token) {
      this.loginOptionShow = true
      //   this.$nextTick(() => {
      //     console.log(this.$refs.loginGoogle)
      //     this.$refs.loginGoogle.onGoogle()
      //   })
    }
  }
}
</script>

<style lang="less" scoped>
.theme-t {
  color: #e3ff7f;
}
.m-home {
  padding: 0 10%;
  min-height: calc(100vh - 120px);

  .pricing {
    color: #fff;
    font-weight: bold;
    font-size: 44px;
    text-align: center;
    font-family: Poppins, Poppins;
    &-details {
      display: flex;
      align-items: center;
      justify-content: center;
      width: fit-content;
      height: 76px;
      margin: 40px auto 0;
      padding: 0 24px;
      border-radius: 16px;
      color: #2e2e33;
      font-size: 28px;
      background-color: #e3ff7f;
    }
  }

  .paymethod {
    min-height: 60vh;
    padding-top: 94px;
    &-title {
      font-weight: 600;
      font-size: 28px;
      color: #ffffff;
      text-align: center;
      font-family: Poppins, Poppins;
    }

    &-item {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 76%;
      height: 92px;
      margin: 30px auto 0;
      border-radius: 20px;
      background-color: #fff;

      img {
        width: 45px;
        height: 40px;
        margin-right: 10px;
      }
      .icon {
        width: 145px;
        height: 66px;
        margin: 0;
      }
      .icon_more {
        width: fit-content;
        height: 66px;
        margin: 0;
        transform: scale(0.66);
      }

      span {
        font-size: 28px;
      }
    }
    .select {
      color: #000 !important;
      background-color: #e0e722 !important;
      border: none !important;
    }
  }
  .coupon-code {
    margin: 80px auto 120px;
    padding: 50px 0;
    border-top: 2px solid #fff;
    border-bottom: 2px solid #fff;

    .coupon-input {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 75%;
      margin: 0 auto;
      border: solid 1px #ebebeb;
      border-radius: 20px;
      padding: 20px;

      .preicon {
        font-weight: 500;
        font-size: 24px;
        color: #ffffff;
        padding-right: 20px;
        border-right: 2px solid #fff;
      }

      .text {
        width: 100%;
        border: none;
        outline: none;
        color: #fff;
        font-size: 30px;
        padding-left: 24px;
        background-color: transparent;
      }
    }
  }

  .rentbtn {
    width: 76%;
    border-radius: 20px;
    padding: 30px 0;
    font-size: 32px;
    color: #2f2f33;
    text-align: center;
    font-weight: 500;
    font-family: "Montserrat-SemiBold";
    background-color: #e3ff7f;
    max-height: 108px;
    margin: 40px auto 0;
  }

  .home-agreen {
    width: 82%;
    color: #fff;
    text-align: center;
    margin: 30px auto 20px;
    font-family: Poppins, Poppins;
    font-size: 22px;

    a {
      font-weight: bold;
      text-decoration: none;
    }
  }
  .home-logo {
    width: 100%;
    padding: 90px 0 60px;
    text-align: center;
    img {
      width: 216px;
    }
  }
}

.buttonDiv /deep/ .S9gUrf-YoZ4jf {
  iframe {
    width: 100% !important;
    margin: 0 auto !important;
  }
}

.buttonDiv {
  position: relative;
  z-index: 99;
  top: 0;
  opacity: 0;
}

.mask {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(53, 53, 53, 0.5);
  z-index: 100;

  .mask-main {
    position: absolute;
    bottom: 0;
    width: 100%;
    box-sizing: border-box;
    background: #fff;
    border-top-right-radius: 100px;
    border-top-left-radius: 100px;
    padding: 40px;
  }

  .appleline {
    background-color: #ebebeb;
    width: 30%;
    height: 8px;
    border-radius: 100px;
    margin: 0 auto 40px;
  }

  .loginoptions {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    margin: 60px 0;

    &-item {
      border: solid 1px #ebebeb;
      border-radius: 20px;
      color: #666666;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 10px 20px;
      margin: 10px 25px 0 0;

      img {
        width: 30px;
        height: 40px;
        margin-right: 10px;
      }
    }
  }

  .phonelogin {
    border: solid 1px #ebebeb;
    border-radius: 20px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    margin-bottom: 40px;

    img {
      width: 60px;
      height: 60px;
      border-radius: 100%;
      transform: rotateZ(180deg);
    }

    input {
      flex: 1;
      font-size: medium;
      border: none;
      outline: none;
    }

    .captcha {
      width: 180px;
    }

    .area {
      width: 100px;
      padding: 0 10px;
      display: flex;
      justify-content: space-around;
      align-items: center;
    }

    .area-down {
      // margin-left: 10px;
      width: 0;
      height: 0;
      border-width: 10px 10px 0;
      border-style: solid;
      border-color: black transparent transparent;
    }
  }

  .facebookbtn {
    border: solid 5px #dadce0;
    color: #3c4043;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    padding: 20px 0;
    margin-bottom: 40px;
    font-size: 28px;

    img {
      width: 40px;
      height: 40px;
      margin-right: 10px;
    }
  }

  .goolebtn {
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fff;
    border: solid 1px #ebebeb;
    color: #000;
    width: 100%;
    padding: 20px 0;
    margin-bottom: 40px;
    font-size: 28px;

    img {
      width: 60px;
      height: 60px;
      margin-right: 10px;
    }
  }

  .mask-agreen {
    width: 86%;
    color: #6e6e6e;
    text-align: center;
    margin: 40px auto 20px;
    font-size: 26px;

    a {
      text-decoration: none;
      color: #202122;
      font-weight: 600;
    }
  }
  .googlewarp {
    position: absolute;
    top: 0;
    z-index: 90;
    width: 100%;
    border: solid 5px #dadce0;
    color: #3c4043;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    padding: 20px 0;

    img {
      width: 40px;
      height: 40px;
      margin-right: 10px;
    }
  }
}

.creditcard-input {
  margin: 48px 48px 48px;
  padding: 20px;
  border-radius: 20px;
  border: 1px solid #e4e7ed;
}

.creditcard-btn {
  margin: 48px;
}
</style>
