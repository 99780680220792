<template>
  <div class="use-guide">
    <h2 class="guide-text use-guide-title">
      THANK <span class="theme-t">YOU</span>
    </h2>
    <p class="guide-text">
      FOR STAYING CHARGED WITH <span class="theme-t">ZAPPY</span>
    </p>
    <p class="guide-text guide-step-title">USING YOUR BATTERY</p>
    <ul class="guide-step">
      <li class="guide-step-li">
        <img src="../../assets/imgs/guide_step_1.png" alt="" />
        <p class="guide-text">
          Battery will <span class="theme-t">dispense</span> from slot
        </p>
      </li>
      <li class="guide-step-li">
        <img src="../../assets/imgs/guide_step_2.png" alt="" />
        <p class="guide-text">
          Ensure <span class="theme-t">power</span> is turned
          <span class="theme-t">on</span>
        </p>
      </li>
      <li class="guide-step-li">
        <img src="../../assets/imgs/guide_step_3.png" alt="" />
        <p class="guide-text">
          Choose the <span class="theme-t">cable</span> for your device
        </p>
      </li>
      <li class="guide-step-li">
        <img src="../../assets/imgs/guide_step_4.png" alt="" />
        <p class="guide-text">
          <span class="theme-t">Plug in,</span> start charging and
          <span class="theme-t">stay positive!</span>
        </p>
      </li>
    </ul>
    <div class="download-btn" @click="onDownload">
      <span>{{ i18n.download }}</span>
    </div>
    <div class="use-guide-logo">
      <img src="../../assets/imgs/icon_logo.png" alt="" />
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex"
export default {
  components: {},
  computed: {
    ...mapState(["browser", "system"]),
    ...mapGetters(["locale"]),
    i18n() {
      return this.$t("result")
    }
  },
  data() {
    return {}
  },
  methods: {
    onDownload() {
      let downloadUrl = process.env.VUE_APP_GOOGLE_PLAY
      if (this.system === "ios") {
        downloadUrl = process.env.VUE_APP_APP_STORE
      }
      window.open(downloadUrl)
    }
  },
  created() {}
}
</script>

<style lang="less" scoped>
.theme-t {
  color: #e3ff7f;
}
.use-guide {
  height: 100vh;
  overflow-x: auto;
  font-size: 24px;
  background-color: #2f2f33;
  .guide-text {
    color: #fff;
    text-align: center;
    font-family: Poppins, Poppins;
  }
  &-title {
    margin: 32px 0 0;
    padding: 20px;
    font-size: 68px;
    font-weight: bold;
  }
  &-logo {
    text-align: center;
    margin: 60px auto;
    img {
      width: 216px;
    }
  }
}
.guide-step {
  &-title {
    margin: 60px 0 20px;
    font-size: 32px;
  }
  &-li {
    width: 80%;
    margin: 0 auto;
    padding: 32px 0;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid #ccc;
    img {
      width: 184px;
      margin-bottom: 20px;
    }
    &:last-child {
      border-bottom: none;
    }
  }
}

.download-btn {
  width: 64%;
  margin: 32px auto 0;
  padding: 24px 0 10px;
  border-radius: 20px;
  text-align: center;
  background-color: #e3ff7f;
  span {
    display: block;
    color: #2f2f33;
    font-size: 48px;
    font-weight: bold;
    font-family: "DIN Condensed Bold";
  }
}
</style>
