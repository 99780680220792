<template>
  <div class="home">
    <div class="home-head">
      <img class="home-head-logo" src="../assets/imgs/img_logo.png" alt="" />
    </div>
    <div class="home-body">
      <h3 class="home-body-title">DOWNLOAD THE ZAPPY APP</h3>
      <div class="home-download">
        <div class="home-download-btn" @click="onAppStore">
          <img
            class="home-download-pic"
            src="../assets/imgs/appstore.png"
            alt=""
          />
        </div>
        <div class="home-download-btn" @click="onGooglePlay">
          <img
            class="home-download-pic"
            src="../assets/imgs/googleplay.png"
            alt=""
          />
        </div>
      </div>
      <div class="home-without">
        <p class="home-without-or">OR</p>
        <div
          class="home-without-app"
          @click="goRouter(`/loginborrow?qrcode=${qrcode || ''}`)"
        >
          Continue Without App
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex"
import { QR_CODE, ZH_CODE } from "../apis/cabinet"
import { CREATE_VIP_ORDER, BALANCE_TO_RENT } from "../apis/payment"
import { USER_INFO, OEDER_COUNT } from "../apis/user"
export default {
  components: {},
  watch: {},
  computed: {
    ...mapState([
      "qrcode",
      "zhcode",
      "cabinet",
      "browser",
      "user",
      "system",
      "lang",
      "headLogo"
    ]),
    ...mapGetters(["locale"]),
    i18n() {
      return this.$t("home")
    },
    i18nMsg() {
      return this.$t("msg")
    },
    title() {
      return process.env.VUE_APP_NAME
    },
    code() {
      return this.qrcode || this.zhcode
    },
    scanUrl() {
      if (this.qrcode) {
        return `${QR_CODE}?id=${this.qrcode}`
      }
      if (this.zhcode) {
        return `${ZH_CODE}/${this.zhcode}`
      }
      return ""
    },
    shopDesc() {
      return `${this.i18n.price_info}`.format(this.cabinet)
    },
    amount() {
      if (this.cabinet) {
        return this.cabinet.yajin
        // return (this.cabinet.yajin - this.cabinet.myyue).toFixed(2)
      } else {
        return 0
      }
    },
    isView() {
      return this.isScan && this.cabinet && !this.isBtns
    }
  },
  data() {
    return {
      isScan: false,
      isBtns: false,
      inputCodeShow: false,
      mainHeight: 400,
      ua: navigator.userAgent.toLowerCase(),
      markerList: [],
      oldMarkerList: [],
      cabinetCode: "",
      latitude: "113.788876", // 113.788876
      longitude: "22.703407", // 22.703407
      userAgreement: false,
      privacyAgreement: false
    }
  },
  methods: {
    showLangSelect() {
      this.$store.commit("isLangView", true)
    },
    goRouter(path) {
      this.$router.push(path)
    },
    onDownload() {
      let downloadUrl = process.env.VUE_APP_GOOGLE_PLAY
      if (this.system === "ios") {
        downloadUrl = process.env.VUE_APP_APP_STORE
      }
      window.open(downloadUrl)
    },
    onAppStore() {
      console.log("11111")
      window.location.href = process.env.VUE_APP_APP_STORE
    },
    onGooglePlay() {
      console.log("22222")
      window.location.href = process.env.VUE_APP_GOOGLE_PLAY
    },

    onBack() {
      this.isScan = false
    },
    codeShowClose() {
      this.inputCodeShow = false
      this.cabinetCode = ""
    },
    onScan(isclick) {
      // if (!this.scanUrl && !isclick) return
      let url = this.scanUrl
      if (isclick && this.cabinetCode) {
        url = `${QR_CODE}?id=${this.cabinetCode}`
        this.$store.commit("qrcode", this.cabinetCode)
      } else if (!this.scanUrl && !isclick) {
        return
      }
      this.inputCodeShow = false
      this.cabinetCode = ""
      this.$loading(true)
      this.$post(
        url,
        {},
        (resp) => {
          this.$loading(false)
          this.$store.commit("cabinet", {
            qrcode: this.code,
            ...resp
          })
          if (resp.zujieNum && resp.zujieNum > 0) {
            this.$confirm({
              title: this.i18n.msg_fail_title,
              content: `${this.i18n.msg_rentcount}`.format(resp),
              confirmText: this.i18nMsg.confirm,
              // cancelText: this.i18nMsg.cancel,
              showCancel: false
            })
              .then(() => {
                // this.$router.push('/order')
                this.$store.commit("qrcode", "")
              })
              .catch(() => {
                this.$store.commit("qrcode", "")
              })
          } else {
            this.isScan = true
          }
        },
        (error) => {
          this.$loading(false)
          this.$confirm({
            title: this.i18n.msg_fail_title,
            confirmText: this.i18n.confirm,
            content: error,
            showCancel: false
          }).then(() => {
            this.$store.commit("qrcode", "")
          })
          // this.$toast(error)
        }
      )
    },
    onSubmit() {
      if (!this.userAgreement || !this.privacyAgreement) {
        this.$toast("Please review and agree to the relevant agreement below")
        return
      }
      this.$router.push({
        path: "/confirm",
        query: {
          price: this.amount,
          payType: "YJ",
          cabinetID: this.cabinet.cabinetID
        }
      })
    },
    onBorrow() {
      this.$loading(true)
      this.$post(
        `${BALANCE_TO_RENT}?qrcode=${this.cabinet.qrcode}`,
        {},
        (resp) => {
          this.$loading(false)
          console.log(resp)
          if (resp.orderid) {
            this.$router.push(`/result?tradeNo=${resp.orderid}`)
          } else {
            this.$confirm({
              title: this.i18n.msg_fail_title,
              content: this.i18n.msg_fail_content,
              confirmText: this.i18n.confirm,
              showCancel: false
            })
          }
        },
        (error) => {
          this.$loading(false)
          this.$toast(error)
        }
      )
    },
    onVIP() {
      this.$loading(true)
      this.$post(
        `${CREATE_VIP_ORDER}/${this.cabinet.qrcode}`,
        {},
        (resp) => {
          this.$loading(false)
          // console.log(resp)
          if (resp.orderid) {
            this.$router.push(`/result?tradeNo=${resp.orderid}`)
          } else {
            this.$confirm({
              title: this.i18n.msg_fail_title,
              content: this.i18n.msg_fail_content,
              confirmText: this.i18n.confirm,
              showCancel: false
            })
          }
        },
        (error) => {
          this.$loading(false)
          // console.log(error)
          this.$toast(error)
        }
      )
    },
    loadUser() {
      this.$get(
        USER_INFO,
        {},
        (resp) => {
          if (resp.user) {
            this.$store.commit("user", resp.user)
          } else {
            this.$confirm({
              title: this.i18n.msg_fail_title,
              content: this.i18n.msg_fail_user,
              showOk: false,
              // confirmText: this.i18nMsg.retry,
              cancelText: this.i18nMsg.cancel
            }).then(() => {
              this.loadUser()
            })
          }
        },
        (error) => {
          this.$confirm({
            title: this.i18n.msg_fail_title,
            content: this.i18nMsg.userinfo_fail,
            confirmText: this.i18nMsg.confirm,
            showCancel: false
          })
          console.log(error)
        }
      )
    },
    onCode() {
      if (this.user) {
        this.isBtns = false
        this.loadUser()
        this.onScan()
      } else {
        this.$router.push("/login")
      }
    },
    closeTouch() {
      let node = document.getElementsByTagName("body")[0]
      node.style.overflow = "hidden"
      document
        .getElementsByTagName("body")[0]
        .addEventListener("touchmove", this.handler, { passive: false }) // 阻止默认事件
    },
    openTouch() {
      let node = document.getElementsByTagName("body")[0]
      node.style.overflow = "auto"
      document
        .getElementsByTagName("body")[0]
        .removeEventListener("touchmove", this.handler, { passive: false }) // 打开默认事件
    },
    handler(e) {
      e.preventDefault()
    },
    getOrderCount() {
      this.$get(
        OEDER_COUNT,
        {},
        (resp) => {
          if (resp.noMoney) {
            this.$confirm({
              title: this.i18nMsg.title,
              content: this.i18nMsg.unpaid_order,
              confirmText: this.i18nMsg.go_pay,
              showCancel: false
            }).then(() => {
              this.$router.push("/order")
            })
          }
        },
        (error) => {
          console.log(error)
        }
      )
    },
    // 頭部主頁圖標按鈕
    goHome() {
      this.$store.commit("qrcode", "")
      // console.log('ditu', gMapContext)
      this.isScan = false
    }
  },
  created() {
    // console.log('url: ', window.location.href)
    // let qrCode = this.qrcode || this.$route.query.qrcode
    // console.log('qrCode', qrCode)
    // if (qrCode) {
    //   this.$store.commit('qrcode', qrCode)
    //   if (this.user) {
    //     this.onCode()
    //   }
    // }
    // if (this.user) {
    //   this.getOrderCount()
    // }
  },
  mounted() {}
}
</script>

<style lang="less" scoped>
.home {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  background-color: #2f2f33;
  &-head {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 148px;
    &-logo {
      width: 43%;
    }
  }
  &-body {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    &-title {
      margin: 0;
      color: #fff;
      font-size: 28px;
      font-weight: bold;
      text-align: center;
    }
  }
  &-download {
    margin-top: 60px;
    &-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 545px;
      height: 138px;
      margin: 0 auto 25px;
      border-radius: 22px;
      background-color: #fff;
    }
    &-pic {
      width: 64%;
    }
  }
  &-without {
    margin: 100px 0;
    &-or {
      width: calc(100% - 148px);
      margin: 0 auto;
      padding-top: 42px;
      border-top: 2px solid #f5f5f5;
      color: #fff;
      text-align: center;
    }
    &-app {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 545px;
      height: 76px;
      margin: 36px auto 0;
      border-radius: 16px;
      font-size: 28px;
      color: #302f33;
      background-color: #e3ff7f;
    }
  }
}
</style>
