const code = [
  {
    label: 'Malaysia(60)',
    value: '60'
  },
  {
    label: 'Indonesia(62)',
    value: '62'
  },
  {
    label: 'Philippines(63)',
    value: '63'
  },
  {
    label: 'Singapore(65)',
    value: '65'
  },
  {
    label: 'Thailand(66)',
    value: '66'
  },
  {
    label: 'Brunei(673)',
    value: '673'
  },
  {
    label: 'Japan(81)',
    value: '81'
  },
  {
    label: 'Korea(82)',
    value: '82'
  },
  {
    label: 'Vietnam(84)',
    value: '84'
  },
  {
    label: 'North Korea(850)',
    value: '850'
  },
  {
    label: 'Hong Kong(852)',
    value: '852'
  },
  {
    label: 'Macao(853)',
    value: '853'
  },
  {
    label: 'Cambodia(855)',
    value: '855'
  },
  {
    label: 'Laos(856)',
    value: '856'
  },
  {
    label: 'China(86)',
    value: '86'
  },
  {
    label: 'Taiwan(886)',
    value: '886'
  },
  {
    label: 'Bangladesh(880)',
    value: '880'
  },
  {
    label: 'Turkey(90)',
    value: '90'
  },
  {
    label: 'India(91)',
    value: '91'
  },
  {
    label: 'Pakistan(92)',
    value: '92'
  },
  {
    label: 'Afghanistan(93)',
    value: '93'
  },
  {
    label: 'Sri Lanka(94)',
    value: '94'
  },
  {
    label: 'Myanmar(95)',
    value: '95'
  },
  {
    label: 'Maldives(960)',
    value: '960'
  },
  {
    label: 'Lebanon(961)',
    value: '961'
  },
  {
    label: 'Jordan(962)',
    value: '962'
  },
  {
    label: 'Syria(963)',
    value: '963'
  },
  {
    label: 'Iraq(964)',
    value: '964'
  },
  {
    label: 'Kuwait(965)',
    value: '965'
  },
  {
    label: 'Saudi Arabia(966)',
    value: '966'
  },
  {
    label: 'Oman(968)',
    value: '968'
  },
  {
    label: 'Israel(972)',
    value: '972'
  },
  {
    label: 'Bahrain(973)',
    value: '973'
  },
  {
    label: 'Qatar(974)',
    value: '974'
  },
  {
    label: 'Bhutan(975)',
    value: '975'
  },
  {
    label: 'Mongolia(976)',
    value: '976'
  },
  {
    label: 'Nepal(977)',
    value: '977'
  },
  {
    label: 'Iran(98)',
    value: '98'
  },
  {
    label: 'Russia(7)',
    value: '7'
  },
  {
    label: 'Greece(30)',
    value: '30'
  },
  {
    label: 'Netherlands(31)',
    value: '31'
  },
  {
    label: 'Belgium(32)',
    value: '32'
  },
  {
    label: 'France(33)',
    value: '33'
  },
  {
    label: 'Spain(34)',
    value: '34'
  },
  {
    label: 'Gibraltar(350)',
    value: '350'
  },
  {
    label: 'Portugal(351)',
    value: '351'
  },
  {
    label: 'Luxembourg(352)',
    value: '352'
  },
  {
    label: 'Ireland(353)',
    value: '353'
  },
  {
    label: 'Iceland(354)',
    value: '354'
  },
  {
    label: 'Albania(355)',
    value: '355'
  },
  {
    label: 'Malta(356)',
    value: '356'
  },
  {
    label: 'Cyprus(357)',
    value: '357'
  },
  {
    label: 'Finland(358)',
    value: '358'
  },
  {
    label: 'Bulgaria(359)',
    value: '359'
  },
  {
    label: 'Hungary(336)',
    value: '336'
  },
  {
    label: 'Germany(49)',
    value: '49'
  },
  {
    label: 'Yugoslavia(338)',
    value: '338'
  },
  {
    label: 'Italy(39)',
    value: '39'
  },
  {
    label: 'San Marino(223)',
    value: '223'
  },
  {
    label: 'Vatican(396)',
    value: '396'
  },
  {
    label: 'Romania(40)',
    value: '40'
  },
  {
    label: 'Switzerland(41)',
    value: '41'
  },
  {
    label: 'Liechtenstein(4175)',
    value: '4175'
  },
  {
    label: 'Austria(43)',
    value: '43'
  },
  {
    label: 'United Kingdom(44)',
    value: '44'
  },
  {
    label: 'Denmark(45)',
    value: '45'
  },
  {
    label: 'Sweden(46)',
    value: '46'
  },
  {
    label: 'Norway(47)',
    value: '47'
  },
  {
    label: 'Poland(48)',
    value: '48'
  },
  {
    label: 'Egypt(20)',
    value: '20'
  },
  {
    label: 'Morocco(210)',
    value: '210'
  },
  {
    label: 'Algeria(213)',
    value: '213'
  },
  {
    label: 'Tunisia(216)',
    value: '216'
  },
  {
    label: 'Libya(218)',
    value: '218'
  },
  {
    label: 'Gambia(220)',
    value: '220'
  },
  {
    label: 'Senegal(221)',
    value: '221'
  },
  {
    label: 'Mauritania(222)',
    value: '222'
  },
  {
    label: 'Mali(223)',
    value: '223'
  },
  {
    label: 'Guinea(224)',
    value: '224'
  },
  {
    label: 'Côte d\'Ivoire(225)',
    value: '225'
  },
  {
    label: 'Burkina Faso(226)',
    value: '226'
  },
  {
    label: 'Niger(227)',
    value: '227'
  },
  {
    label: 'Togo(228)',
    value: '228'
  },
  {
    label: 'Benin(229)',
    value: '229'
  },
  {
    label: 'Mauritius(230)',
    value: '230'
  },
  {
    label: 'Liberia(231)',
    value: '231'
  },
  {
    label: 'Sierra Leone(232)',
    value: '232'
  },
  {
    label: 'Ghana(233)',
    value: '233'
  },
  {
    label: 'Nigeria(234)',
    value: '234'
  },
  {
    label: 'Chad(235)',
    value: '235'
  },
  {
    label: 'Central Africa(236)',
    value: '236'
  },
  {
    label: 'Cameroon(237)',
    value: '237'
  },
  {
    label: 'Cape Verde(238)',
    value: '238'
  },
  {
    label: 'Sao Tome(239)',
    value: '239'
  },
  {
    label: 'Princeby(239)',
    value: '239'
  },
  {
    label: 'Equatorial Guinea(240)',
    value: '240'
  },
  {
    label: 'Gabon(241)',
    value: '241'
  },
  {
    label: 'Congo(242)',
    value: '242'
  },
  {
    label: 'Zaire(243)',
    value: '243'
  },
  {
    label: 'Angola(244)',
    value: '244'
  },
  {
    label: 'Guinea Bissau(245)',
    value: '245'
  },
  {
    label: 'Ascension(247)',
    value: '247'
  },
  {
    label: 'Seychelles(248)',
    value: '248'
  },
  {
    label: 'Sudan(249)',
    value: '249'
  },
  {
    label: 'Rwanda(250)',
    value: '250'
  },
  {
    label: 'Ethiopia(251)',
    value: '251'
  },
  {
    label: 'Somali(252)',
    value: '252'
  },
  {
    label: 'Djibouti(253)',
    value: '253'
  },
  {
    label: 'Kenya(254)',
    value: '254'
  },
  {
    label: 'Tanzania(255)',
    value: '255'
  },
  {
    label: 'Uganda(256)',
    value: '256'
  },
  {
    label: 'Burundi(257)',
    value: '257'
  },
  {
    label: 'Mozambique(258)',
    value: '258'
  },
  {
    label: 'Zambia(260)',
    value: '260'
  },
  {
    label: 'Madagascar(261)',
    value: '261'
  },
  {
    label: 'Reunion Island(262)',
    value: '262'
  },
  {
    label: 'Zimbabwe(263)',
    value: '263'
  },
  {
    label: 'Namibia(264)',
    value: '264'
  },
  {
    label: 'Malawi(265)',
    value: '265'
  },
  {
    label: 'Lesotho(266)',
    value: '266'
  },
  {
    label: 'Botswana(267)',
    value: '267'
  },
  {
    label: 'Swaziland(268)',
    value: '268'
  },
  {
    label: 'Comoros(269)',
    value: '269'
  },
  {
    label: 'South Africa(27)',
    value: '27'
  },
  {
    label: 'Saint Helena(290)',
    value: '290'
  },
  {
    label: 'Aruba(297)',
    value: '297'
  },
  {
    label: 'Faroe Islands(298)',
    value: '298'
  },
  {
    label: 'United States(1)',
    value: '1'
  },
  {
    label: 'Canada(1)',
    value: '1'
  },
  {
    label: 'Midway Island(1808)',
    value: '1808'
  },
  {
    label: 'Hawaii(1808)',
    value: '1808'
  },
  {
    label: 'Wake Island(1808)',
    value: '1808'
  },
  {
    label: 'Anguilla Island(1809)',
    value: '1809'
  },
  {
    label: 'Virgin Islands(1809)',
    value: '1809'
  },
  {
    label: 'Saint Lucia(1809)',
    value: '1809'
  },
  {
    label: 'Puerto Rico(1809)',
    value: '1809'
  },
  {
    label: 'Jamaica(1809)',
    value: '1809'
  },
  {
    label: 'The Bahamas(1809)',
    value: '1809'
  },
  {
    label: 'Barbados(1809)',
    value: '1809'
  },
  {
    label: 'Alaska(1907)',
    value: '1907'
  },
  {
    label: 'Greenland(299)',
    value: '299'
  },
  {
    label: 'Falkland Islands(500)',
    value: '500'
  },
  {
    label: 'Belize(501)',
    value: '501'
  },
  {
    label: 'Guatemala(502)',
    value: '502'
  },
  {
    label: 'El Salvador(503)',
    value: '503'
  },
  {
    label: 'Honduras(504)',
    value: '504'
  },
  {
    label: 'Nicaragua(505)',
    value: '505'
  },
  {
    label: 'Costa Rica(506)',
    value: '506'
  },
  {
    label: 'Panama(507)',
    value: '507'
  },
  {
    label: 'Haiti(509)',
    value: '509'
  },
  {
    label: 'Peru(51)',
    value: '51'
  },
  {
    label: 'Mexico(52)',
    value: '52'
  },
  {
    label: 'Cuba(53)',
    value: '53'
  },
  {
    label: 'Argentina(54)',
    value: '54'
  },
  {
    label: 'Brazil(55)',
    value: '55'
  },
  {
    label: 'Chile(56)',
    value: '56'
  },
  {
    label: 'Colombia(57)',
    value: '57'
  },
  {
    label: 'Venezuela(58)',
    value: '58'
  },
  {
    label: 'Bolivia(591)',
    value: '591'
  },
  {
    label: 'Guyana(592)',
    value: '592'
  },
  {
    label: 'Ecuador(593)',
    value: '593'
  },
  {
    label: 'French Guiana(594)',
    value: '594'
  },
  {
    label: 'Paraguay(595)',
    value: '595'
  },
  {
    label: 'Martinique(596)',
    value: '596'
  },
  {
    label: 'Suriname(597)',
    value: '597'
  },
  {
    label: 'Uruguay(598)',
    value: '598'
  },
  {
    label: 'Australia(61)',
    value: '61'
  },
  {
    label: 'New Zealand(64)',
    value: '64'
  },
  {
    label: 'Guam(671)',
    value: '671'
  },
  {
    label: 'Cocos Island(6722)',
    value: '6722'
  },
  {
    label: 'Norfolk Island(6723)',
    value: '6723'
  },
  {
    label: 'Christmas Island(6724)',
    value: '6724'
  },
  {
    label: 'Nauru(674)',
    value: '674'
  },
  {
    label: 'Tonga(676)',
    value: '676'
  },
  {
    label: 'Solomon Islands(677)',
    value: '677'
  },
  {
    label: 'Vanuatu(678)',
    value: '678'
  },
  {
    label: 'Fiji(679)',
    value: '679'
  },
  {
    label: 'Cork Islands(682)',
    value: '682'
  },
  {
    label: 'Niue(683)',
    value: '683'
  },
  {
    label: 'East Samoa(684)',
    value: '684'
  },
  {
    label: 'Western Samoa(685)',
    value: '685'
  },
  {
    label: 'Kiribati(686)',
    value: '686'
  },
  {
    label: 'Tuvalu(688)',
    value: '688'
  }
]

export default code
