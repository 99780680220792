export default {
  home: {
    minute: 'minute',
    price_info: '{mianfei} minutes gratuites, maximum par jour {currencyName}{fengding}, caution {currencyName}{yajin}, caution de location gratuite',
    feature_1: 'Conception spéciale pour chargement sans fil',
    feature_2: 'Emprunt et remboursement national universel',
    feature_3: 'Quatre modes de chargement adaptés à tous les téléphones mobiles',
    submit: 'Rechargement',
    borrow: 'Emprunt',
    inputcodetitle: 'Veuillez saisir le numéro d’appareil affiché sur la vitrine',
    msg_rentcount: 'Vous avez actuellement {zujieNum} locations non clôturées',
    cancel: 'Annuler',
    confirm: 'Confirmer',
    msg_fail_title: 'Erreur',
    msg_fail_content: 'Location échouée, veuillez réessayer',
    shoptime: 'Temps commercial',
    supportrental: 'Location de soutien',
    supportreturn: 'Retour de soutien',
    useragreen: 'Cliquez pour accepter le « Formulaire d’autorisation de délégation de débit », l’« Accord utilisateur », l’« Accord de couverture complémentaire »',
    privacyagreen: 'Cliquez pour accepter la politique de confidentialité',
    offline: 'L’appareil {devicecode} est hors-ligne, veuillez contacter le personnel disponible',
    permissionfail: 'Autorisation refusée.',
    unbound: 'Le code QR {devicecode} n’est pas lié à l’appareil',
    nobattery: 'Aucune batterie disponible dans l’appareil {devicecode}'
  },
  loginborrow: {
    powerbanktitle: 'Connectez-vous pour déverrouiller votre chargeur portatif',
    msg_fail_title: 'Erreur',
    msg_fail_user: 'Erreur d’acquisition des informations de l’utilisateur. Voulez-vous réessayer?',
    msg_rentcount: 'Vous avez actuellement {zujieNum} locations non clôturées',
    msg_fail_support: 'Le système n’est pas pris en charge',
    msg_fail_confirm: 'Confirmer',
    msg_fail_retry: 'Réessayer',
    msg_fail_cancel: 'Annuler',
    msg_fail_order: 'Échec de création du contrat de location',
    msg_fail_paymod: 'Échec d’obtention de méthode de paiement',
    msg_fail_login: 'Échec de la connexion',
    Apple: 'Apple',
    otheropt: 'Autres options',
    selectmethod: 'Sélectionnez votre méthode de paiement',
    paymentdetail: 'Informations de méthode de paiement',
    couponcode: 'Code de coupon',
    rent: 'Confirmer le paiement',
    pricing: 'Voir les tarifs',
    phone: 'Téléphone',
    Google: 'Google',
    Facebook: 'Facebook',
    loginfb: 'Se connecter avec Facebook',
    logingg: 'Se connecter avec Google',
    captcha_code: 'Code',
    phonenumber: 'Saisissez votre numéro de téléphone',
    msg_fail_code: 'Veuillez saisir le code de vérification',
    msg_fail_phone: 'Veuillez saisir votre numéro de téléphone',
    logout: 'Déconnexion',
    savenow: 'Enregistrer',
    delete: 'Supprimer',
    addcard: 'Saisissez les informations de carte',
    msg_fail_content: 'Location échouée, veuillez réessayer',
    title: 'Veuillez confirmer l’autorisation',
    policy: 'Police.',
    term: ' Conditions générales',
    and: 'et',
    privacy: 'Politique de confidentialité.',
    tips: 'En continuant vous acceptez notre ',
    cancel: 'ANNULER',
    agree: 'ACCEPTER'
  },
  preauth: {
    preauthortitle: 'À propos de la préautorisation',
    tip1: 'Lorsque vous entamez une location, votre méthode de paiement est préautorisée. Veillez à avoir assez de fonds à disposition pour payer votre location.',
    tip2: 'Votre banque peut vous envoyer une notification, mais il ne s’agit pas d’un débit : seul le prix de votre location sera débité.',
    tip3: 'En fonction de votre banque, le montant peut prendre plusieurs heures, voire plusieurs jours, avant d’apparaître sur vos relevés, ce qui est normal.'
  },
  rentcost: {
    rentcosttitle: 'Combien coûte une location?',
    tip1: 'Si vous ne rendez pas votre chargeur portatif dans les 48 heures, vous devrez payer une pénalité de non-retour de {currency} {yajin} .',
    tip2: 'La durée est arrondie à la demi-heure la plus proche (30 ou 60 minutes).',
    tip3: 'Les locations d’un jour durent 24 heures à partir du moment où le chargeur portatif est récupéré.',
    tip3_1: 'Des frais de préautorisation d’un montant de {currency}{currencyName} {cyajin} seront imputés à votre méthode de paiement, afin de garantir que vous disposez de fonds suffisants pour payer votre location.',
    tip3_2: 'Votre banque peut vous envoyer une notification, mais il ne s’agit pas d’un débit; seul le prix de votre location sera débité.',
    tip3_3: 'En fonction de votre banque, le montant peut prendre plusieurs heures voire plusieurs jours avant d’apparaître sur vos relevés.',
    tip4: 'Certains établissements peuvent proposer des heures gratuites ou des réductions. Profitez-en!',
    Days: 'Jours',
    Hours: 'Heures',
    Minutes: 'Minutes'
  },
  logincode: {
    login: 'Connexion',
    retry: 'Renvoyer',
    title: 'Code de vérification',
    subtitle: 'Veuillez saisir le code de vérification que nous avons envoyé à votre numéro de téléphone'
  },
  agreenconfirm: {
    title: 'Veuillez confirmer l’autorisation',
    policy: 'Police.',
    term: 'Les Conditions générales',
    and: 'et la',
    privacy: 'Politique de confidentialité d’Eternal Technology.',
    tips: 'J’accepte',
    cancel: 'ANNULER',
    agree: 'ACCEPTER'
  },
  users: {
    username: 'Nom d’utilisateur',
    mobile: 'Cellulaire',
    login: 'Connexion',
    orders: 'Contrats de location',
    wallet: 'Mon portefeuille',
    joinus: 'Coopérez pour rejoindre',
    langsetting: 'Paramètres de langue'
  },
  login: {
    login_fail: 'Échec de la connexion',
    phonenumber: 'Numéro de téléphone',
    verificationcode: 'Code de vérification',
    Rentpowerbank: 'Louer un chargeur portatif',
    Rentpowerbanktip: 'Saisissez votre numéro de téléphone mobile pour commencer',
    phone_number: 'Veuillez saisir un numéro à {0} chiffres ',
    captcha_code: 'Code',
    send_code: 'Envoyer le code',
    login: 'Connexion',
    agree: 'En cliquant sur Connexion, vous acceptez',
    readme: `le Contrat d’utilisation`,
    msg_fail_login: 'Échec de la connexion',
    msg_success_login: 'Connexion réussie',
    msg_success_login_code: 'Reçu avec succès, veuillez télécharger l’APPLI pour vous connecter afin de l’utiliser',
    msg_fail_user: 'Erreur d’acquisition des informations de l’utilisateur. Voulez-vous réessayer?',
    no_sms: 'Je n’ai pas reçu le SMS',
    retry: 'Renvoyer',
    receive: 'Demander',
    invite_pwd: 'Code d’invitation',
    vip_agreen_tip: 'En continuant, vous acceptez nos',
    vip_agreen_service: 'Conditions d’utilisation',
    vip_agreen_and: 'et la',
    vip_agreen_policy: 'Politique de confidentialité'
  },
  confirm: {
    title: 'Confirmation de paiement',
    pay_label: 'Montant',
    addcard: 'Ajouter une carte-portefeuille',
    price_tips: 'Cliquez pour confirmer le paiement et indiquer que vous avez lu et acceptez ',
    price_btn: '« l’Accord sur la valeur ajoutée »',
    pay_type: 'Veuillez sélectionner votre méthode de paiement de préférence',
    submit: 'Confirmer la location',
    charge: 'Confirmer la recharge',
    msg_fail_title: 'Avertissement',
    msg_fail_order: 'Échec de création de contrat de location',
    msg_fail_confirm: 'Confirmer',
    msg_fail_retry: 'Réessayer',
    msg_fail_cancel: 'Annuler'
  },
  join: {
    title: 'Partenariat',
    call: 'Appel',
    tips: 'Indiquez vos coordonnées, nous vous contacterons sous peu!',
    type1: 'Installation',
    type2: 'Publicité',
    type3: 'Autre',
    shop_name: 'Nom du marchand',
    contact_person: 'Personne-ressource',
    phone: 'Numéro du contact',
    submit: 'Soumettre',
    noempty: 'Ne peut pas être vide'
  },
  mine: {
    title: 'Profil utilisateur',
    wallet: 'Mon portefeuille',
    balance: 'Mes fonds',
    mywalletcard: 'Ma carte',
    addcard: 'Ajouter une carte-portefeuille',
    deposit: 'Caution',
    wallet_in: 'Recharger',
    wallet_out: 'Retirer',
    wallet_log: 'Journal de portefeuille',
    order: 'Contrat',
    login_tips: 'Veuillez vous connecter',
    msg_fail_title: 'Erreur',
    msg_fail_user: 'Erreur d’acquisition des informations de l’utilisateur. Voulez-vous réessayer?',
    help: 'Aide',
    setting: 'Paramètres',
    logout: 'Déconnexion',
    unpaid: 'Non payé',
    using: 'En usage'
  },
  msg: {
    nopaymode: 'Échec de l’acquisition de la méthode de paiement',
    unlogin: 'Vous n’êtes pas encore connecté, veuillez vous connecter',
    msg_fail_title: 'Avertissement',
    deletecard: 'Voulez-vous vraiment supprimer cette carte?',
    loading: 'Chargement…',
    title: 'Astuces',
    success: 'Opération réussie',
    error: 'Erreur inconnue, veuillez réessayer ultérieurement.',
    userinfo_fail: 'Informations de l’utilisateur invalides, veuillez vous reconnecter',
    roleerror: 'Autorisations inadéquates, veuillez consulter l’administrateur',
    neterror: 'Échec de la connexion au réseau. Veuillez réessayer ultérieurement',
    confirm: 'Confirmer',
    retry: 'Réessayer',
    cancel: 'Annuler',
    msg_logout: 'Voulez-vous vraiment vous déconnecter?',
    vieworder: 'Voir le contrat',
    addorselectcard: 'Veuillez ajouter ou sélectionner un ensemble de cartes pour continuer',
    success_payment: 'Paiement effectué',
    fail_payment: 'Échec du paiement',
    keyword: 'mot-clé',
    unpaid_order: 'Il reste des contrats non payés',
    go_pay: 'Aller au paiement',
    no_more: 'pas plus',
    noshop: 'Aucun marchand',
    msg_fail_code: 'Veuillez saisir le code de vérification',
    msg_fail_phone: 'Veuillez saisir votre numéro de téléphone',
    exceedorder: 'Vous avez déjà un contrat en cours, veuillez rendre la location avant de continuer'
  },
  result: {
    title: 'Location confirmée',
    tips: 'Pour voir les contrats et les autres lieux d’Eternal Technology, veuillez télécharger l’application.',
    download: 'TÉLÉCHARGER',
    vieworder: 'Voir le contrat',
    msg_result_loading: 'Résultat du paiement en cours de confirmation…',
    msg_tips_loading: 'Le chargeur portatif va apparaître, veuillez patienter…',
    haveorder: 'Le contrat officiel a été généré'
  },
  result_msg: {
    loading: 'Chargement…',
    refresh: 'Rafraîchir'
  },
  order: {
    title: 'Contrat de location',
    in_use: 'En cours',
    ended: 'Terminé',
    msg_error: 'Échec de la demande',
    no_data: 'Aucune donnée'
  },
  order_item: {
    order_number: 'No.',
    venue_rented: 'Lieu loué',
    time_rented: 'Heure de location',
    venue_returned: 'Lieu rendu',
    time_returned: 'Heure de retour',
    charge_standard: 'Frais standard',
    rent_occurred: 'Loyer accumulé',
    returned: 'Rendu',
    unreturned: 'Non rendu',
    type_0: 'Non effectué',
    type_1: 'Loué',
    type_2: 'Annulé',
    type_3: 'Rendu',
    type_4: 'Un défaut a eu lieu',
    type_5: 'Dépassement',
    min: 'Min',
    unpaid: 'Non payé',
    payment: 'Paiement'
  },
  setting: {
    title: 'Paramètres',
    lang: 'Langue'
  },
  help: {
    title: 'FAQ'
  },
  wallet_in: {
    title: 'Ajouter de la valeur',
    price_label: 'Veuillez sélectionner un autre montant à recharger',
    price_placeholder: 'Veuillez saisir le montant à recharger',
    balance: 'Mes fonds',
    chargemoney: 'Veuillez sélectionner le montant à recharger',
    submit: 'Soumettre',
    loading: 'Chargement…',
    msg_title: 'Rappel'
  },
  wallet_out: {
    title: 'Retirer',
    price_label: 'Veuillez saisir le montant à retirer',
    price_placeholder: 'Veuillez saisir le montant à retirer',
    balance: 'Fonds actuels',
    balance_all: 'Tout retirer',
    deposit: 'Caution',
    deposit_tips: 'Un contrat de location est en cours. La caution de {pcurrencyName}{pyajin} n’est pas remboursable',
    submit: 'Soumettre',
    apply: 'Demander',
    confirm: 'Confirmer',
    loading: 'Chargement…',
    msg_fail_price: 'Le montant à retirer est incorrect',
    msg_fail_balance: 'Le montant à retirer ne peut pas être plus élevé que les fonds actuels',
    msg_title: 'Erreur',
    msg_success_content: 'Demande de remboursement réussie',
    tips_title: 'Explication',
    btn: 'Soumettre',
    error_tips: 'Veuillez saisir des informations complètes'
  }
}
