<template>
  <page back :logo="false">
    <section class="m-expense">
      <h2 class="expense-title text">PRICING DETAILS</h2>
      <div class="expense-price">
        <p class="text">
          Battery rentals start at
          <span class="theme-t">{{ currencyName }}{{ cabinet.jifei }}</span> for
          every
          <span class="theme-t"
            >{{ cabinet.jifeiDanwei }}-{{ i18n.Minutes }}</span
          >
          of use for a maximum charge of
          <span class="theme-t">{{ currencyName }}{{ cabinet.fengding }}</span
          >.
        </p>
        <p class="text">
          A <span class="theme-t">pre-authorization</span> of
          <span class="theme-t">{{ currencyName }}{{ cabinet.yajin }}</span>
          will be made to your account at the time of rental.
        </p>
        <p class="text" v-if="priceStrategy">
          If the battery is not returned after
          <span class="theme-t"
            >{{ parseInt(priceStrategy.pOvertimeDay * 24) || 0 }} hours</span
          >
          of use, a charge of
          <span class="theme-t"
            >{{ currencyName }}{{ priceStrategy.pOvertimeAmount || 0 }}</span
          >
          will be applied, and the battery is yours to keep and reuse.
        </p>
      </div>
      <div class="expense-us">
        <h3 class="expense-us-title" @click="goRouter('help')">NEED HELP?</h3>
        <p class="text">1-844-44ZAPPY</p>
        <p class="text">Support@needzappy.com</p>
      </div>
      <div class="m-expense-logo">
        <img src="../../assets/imgs/icon_logo.png" alt="" />
      </div>
    </section>
  </page>
</template>

<script>
import { mapState, mapGetters } from "vuex"
export default {
  components: {},
  computed: {
    ...mapState(["user", "cabinet"]),
    ...mapGetters(["locale"]),
    i18n() {
      return this.$t("loginborrow")
    },
    currencyName() {
      return (
        (this.cabinet && this.cabinet.currencyName) ||
        (this.user && this.user.pcurrencyName) ||
        "$"
      )
    },
    priceStrategy() {
      return (this.cabinet && this.cabinet.priceStrategy) || {}
    }
  },
  data() {
    return {}
  },
  methods: {
    goRouter(path) {
      this.$router.push(path)
    }
  },
  created() {
    console.log(this)
  }
}
</script>

<style lang="less" scoped>
.theme-t {
  color: #e3ff7f;
}
.m-expense {
  height: calc(100vh - 120px);
  overflow-x: auto;
  padding: 0 10%;
  background-color: #2f2f33;

  .text {
    color: #fff;
    font-size: 26px;
    font-family: Poppins, Poppins;
  }
  .expense-title {
    font-size: 38px;
    font-weight: bold;
    text-align: center;
    margin: 42px 0 72px;
  }
  .expense-price {
    p {
      margin-bottom: 24px;
    }
  }
  .expense-us {
    margin-top: 86px;
    padding-top: 52px;
    border-top: 2px solid #ccc;
    &-title {
      color: #fff;
      font-size: 32px;
      font-weight: bold;
      font-family: Poppins, Poppins;
      text-align: center;
      margin: 0 0 32px;
    }
    .text {
      text-align: center;
      font-size: 28px;
      margin: 12px 0;
    }
  }
  &-logo {
    position: absolute;
    bottom: 60px;
    left: 0;
    width: 100%;
    text-align: center;
    img {
      width: 216px;
    }
  }
}
</style>
